<template>
  <div>
    <v-container
      class="v-container-fluid"
      fluid
    >
      <v-row>
        <v-col cols="12">
          <div class="container-profile">
            <template>
              <v-tabs
                v-model="tab"
                show-arrows
                background-color="transparent"
              >
                <v-tabs-slider class="separator-line-tab"></v-tabs-slider>
                <v-tab
                  @click="tab1"
                  class="tabs-profile mon-regular"
                >
                  {{ texts.disputes.tabs.text1 }}
                </v-tab>
                <v-tab
                  @click="tab2"
                  class="tabs-profile mon-regular"
                >
                  {{ texts.disputes.tabs.text2 }}
                </v-tab>
              </v-tabs>

              <v-tabs-items :touchless="true" v-model="tab">
                <v-tab-item>
                  <disputes-content-tab-one-layout :texts="texts"></disputes-content-tab-one-layout>
                </v-tab-item>
                <v-tab-item>
                  <disputes-content-tab-two-layout :texts="texts"></disputes-content-tab-two-layout>
                </v-tab-item>
              </v-tabs-items>
            </template>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "ProfileContentLayout",
  props: {
    texts: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      //VARIABLES
      tab: null,
    };
  },
  beforeMount() {
    if (this.$route.path == "/disputes/tab/1") {
      this.tab = 0;
    } else if (this.$route.path == "/disputes/tab/2") {
      this.tab = 1;
    }
  },
  methods: {
    tab1: function () {
      this.$router
        .push({
          path: "/disputes/tab/1",
        })
        .catch(() => {});
    },
    tab2: function () {
      this.$router
        .push({
          path: "/disputes/tab/2",
        })
        .catch(() => {});
    },
  },
  watch: {
    $route(to, from) {
      if (to.path == "/disputes/tab/1") {
        this.tab = 0;
      } else if (to.path == "/disputes/tab/2") {
        this.tab = 1;
      }
    },
  },
};
</script>

<style>
.container-profile .v-slide-group__content {
  margin-bottom: 4px !important;
}
</style>

<style scoped>
.separator-line-tab {
  background: transparent linear-gradient(268deg, #ff8181 0%, #ffa77e 100%) 0%
    0% no-repeat padding-box;
  height: 40px;
  margin-left: 2px;
}

.tabs-profile {
  font-size: 14px;
  text-transform: initial;
  letter-spacing: 0px;
  text-align: center;
}

.v-tab--active {
  font-family: "montserrat-bold", sans-serif;
  color: #3d414a !important;
}

.v-tabs-items {
  background-color: transparent !important;
}

.theme--light.v-tabs .v-tab:focus::before {
  opacity: 0;
}

.theme--light.v-tabs .v-tab--active:hover::before,
.theme--light.v-tabs .v-tab--active::before {
  opacity: 0;
}

.text-title {
  text-align: left;
  font-size: 55px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
}
</style>